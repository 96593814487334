@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import "variables";
@import "~bootstrap/scss/bootstrap";

@media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px; /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #343a40;
    transition-timing-function: ease-in-out;
    transition-duration: .3s;
    transition-property: left, visibility;
  }
  .offcanvas-collapse.open {
    left: 0;
    visibility: visible;
  }
}

body {
  @include media-breakpoint-down(md) {
    overflow-x: hidden;
  }
}

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}

.sidebar {
  @include media-breakpoint-down(md) {
    transform: translateX(-#{$sidebar-width});
    &.active {
      transform: translateX(0);
    }
  }
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: $sidebar-width;
  overflow: hidden;
  overflow-y: auto;
  transition: transform 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);

  .nav {
    .nav-item {
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;

      &:hover {
        .nav-link {
          background: rgba(255, 255, 255, 0.15);
        }
      }
      .nav-link {
        color: #fff;
      }
    }
  }
}

.main {
  @include media-breakpoint-down(md) {
    width: 100%;
    &.sidebar-on {
      transform: translateX(#{$sidebar-width});
    }
  }
  position: relative;
  float: right;
  width: calc(100% - #{$sidebar-width});
  min-height: 100%;
  transition: transform 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.sidebar-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  visibility: hidden;
  background: #000;
  opacity: 0;
  transition: opacity 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);

  &.active {
    @include media-breakpoint-down(md) {
      visibility: visible;
    }
    opacity: 0.5;
    cursor: pointer;
  }
}

textarea {
  resize: vertical;
}

.overlay {
  position: absolute;
  &-top-right {
    top: 0.5rem;
    right: 0.5rem;
  }
  &-top-left {
    top: 0.5rem;
    left: 0.5rem;
  }
}

.overlay-hover {
  transition: opacity ease 0.3s;
}

*:hover > .overlay-hover {
  opacity: 1;
}

* > .overlay-hover {
  opacity: 0.3;
}

.table-vertical-align {
  th, td {
    vertical-align: middle;
  }
}

.custom-control-input:checked ~ .custom-control-label.custom-control-label-warning::before {
  color: #fff;
  border-color: $warning;
  background-color: $warning;
}

.custom-control-input:checked ~ .custom-control-label.custom-control-label-danger::before {
  color: #fff;
  border-color: $danger;
  background-color: $danger;
}

.custom-control-input:checked ~ .custom-control-label.custom-control-label-success::before {
  color: #fff;
  border-color: $success;
  background-color: $success;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label.custom-control-label-warning::before {
  border-color: $warning;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label.custom-control-label-success::before {
  border-color: $success;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label.custom-control-label-danger::before {
  border-color: $danger;
}

.font-size-12 {
  font-size: 12pt;
}

.custom-file-label { opacity: 0; }
.custom-file-input { opacity: 1; }