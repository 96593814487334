$usts-green: #6984ff;
$sidebar-width: 260px;
$selectize-primary: $usts-green;

$font-family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$component-active-bg: $usts-green;
//$input-focus-border-color: transparent;
$input-btn-focus-box-shadow: none;
$btn-focus-width: 0;
$pagination-color: $usts-green;
$link-color: $usts-green;

$theme-colors: (
        "usts-green": $usts-green,
);

$datepicker-primary: $usts-green;
